// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DmBNPW0TBwhFBUOxg7FH {\n  background-image: linear-gradient(0deg, #bac1b8, black);\n  text-align: center;\n  padding-top: 60px;\n  position: static;\n  bottom: 0;\n  width: 100%;\n}\n.VGKUThp_OTKUjo2AebAE {\n  padding-top: 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.vCT7bY9_DwtugSjOqDSA {\n  padding-right: 5px;\n}\n.wuddrLS_TzloiXcylfWb {\n  padding-right: 3px;\n  margin-top: 3px;\n}\n@media (max-width: 490px) {\n  .DmBNPW0TBwhFBUOxg7FH {\n    /* width: 100vh; */\n    width: 100%;\n    padding-right: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,uDAAuD;EACvD,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".footer {\n  background-image: linear-gradient(0deg, #bac1b8, black);\n  text-align: center;\n  padding-top: 60px;\n  position: static;\n  bottom: 0;\n  width: 100%;\n}\n.footerLinks {\n  padding-top: 15px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.footerGithubIcon {\n  padding-right: 5px;\n}\n.footerEmailImage {\n  padding-right: 3px;\n  margin-top: 3px;\n}\n@media (max-width: 490px) {\n  .footer {\n    /* width: 100vh; */\n    width: 100%;\n    padding-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "DmBNPW0TBwhFBUOxg7FH",
	"footerLinks": "VGKUThp_OTKUjo2AebAE",
	"footerGithubIcon": "vCT7bY9_DwtugSjOqDSA",
	"footerEmailImage": "wuddrLS_TzloiXcylfWb"
};
export default ___CSS_LOADER_EXPORT___;
